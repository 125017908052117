import React, { Component } from "react";
import { render } from 'react-dom';
import { Context, RequestAPI} from '../configs/RequestAPI';
import ModGallery from 'react-grid-gallery';


var Loader = require('react-loader');

class Gallery extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isReady:false,
            dataTable:[],
            loaded:false,
        }
    }

    _dataTable(params){
        var ar=[];
        this.setState({
            dataTable:[]
        });

         if(params.data.length>0){
            params.data.map((item,index)=>{
                var img="http://156.67.219.84:8001/storage/gallery/"+item.nama_file;
              ar.push({
                src:img,
                thumbnail: img,
                thumbnailWidth: 320,
                thumbnailHeight: 174

                /*id:item.id_gallery,
                judul:item.judul,
                keterangan:item.keterangan,
                nama_file:item.nama_file,
                tgl_upload:item.tgl_upload,
                ukura:item.ukuran*/
              });
            });
          }

        this.setState({
            dataTable: ar,
            totalItem:params.jml
        });
    }

    _setDataPaging(params){

    }

    _notif(status){

    }

    _loading(status){
        this.setState({
            loaded:status
        });
    }

    componentDidMount() {
        if(!this.state.isReady){
             this.setState({
                isReady:true
             });
        }
    }

	render(){
        return (
            <RequestAPI>
                <Context.Consumer>
                    {(context) =>{

                        if(!this.state.isReady){
                            context.getGalleryFoto(
                                '',
                                this._dataTable.bind(this),
                                this._notif.bind(this),
                                this._loading.bind(this),
                                this._setDataPaging.bind(this),
                                0,
                                1000
                            );
                        }


                        return(
                            <React.Fragment>

        	                   <section id="gallery" className="photo-gallery">
        		                  <div className="container">
            		                  <div className="row text-center">
                		                  <div className="col col-sm-12">
                    		                  <h1 className="heading"><span>photo gallery</span></h1>
                    		                  <p className="sub-heading"></p>
                		                  </div>
            		                  </div>
        		                  </div>
        		                  <div className="container-fluid">
            		                  <div className="row">
                    	                   <div className="col-12">
                        	                   <ModGallery images={this.state.dataTable} />
                    	                   </div>
                	                   </div>
                                    </div>
                                </section>

                        </React.Fragment>
                    )
                }}
                </Context.Consumer>
            </RequestAPI>

        )
    }
}

export default Gallery;