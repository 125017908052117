import React, { Component } from "react";
import { Context, RequestAPI} from '../configs/RequestAPI';
import axios from 'axios';
import qs from "qs";
import BlockUi from 'react-block-ui';
import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';
import InputMask from 'react-input-mask';
import 'react-block-ui/style.css';
PNotify.defaults.styling = 'bootstrap3'; // Bootstrap version 3
PNotify.defaults.icons = 'fontawesome4'; // glyphicons
var Loader = require('react-loader');

class Contact extends Component{
	constructor(props){
		super(props);
		this.state={
			/*xFirstName:'',
			xLastname:'',
			xSubject:'',
			xEmail:'',
			xMessage:'',*/
            isReady:false,
            loaded:false,
            dataNegara:[],
            dataTempatNikah:[],
            dataBudget:[],
            dataFind:[],


            xTanggal:'',
            xNama:'',
            xEmail:'',
            xTelepon:'',
            xNegara:'',
            xNamaPengantin:'',
            xEmailPengantin:'',
            xTelpPengantin:'',
            xNegaraPengantin:'',
            xTempatNikah:'',
            xJmlTamu:'',
            xBudget:'',
            xFind:'',
            xMessage:'',

            xFullScale:'Y',
            xLegal:'Y',
            xFlower:'Y',
            xMakeUp:'Y',
            xCatering:'Y',
            xOpenBar:'Y',
            xWeddingCake:'Y',
            xEntertainment:'Y',
            xSound:'Y',
            xLighting:'Y',
            xStaging:'Y',
            xDance:'Y',
            xFireworks:'Y',
            xPhotography:'Y',
            xVideography:'Y',
            xTransportation:'Y',
            xPrewedding:'Y',
            xPostWedding:'Y',
            xWeddingArrangements:'Y',
            xActivities:'Y',
            xAccomodation:'Y',

		}
		this._handleInputChange=this._handleInputChange.bind(this);
	}

    _notif(type,message){
        PNotify.alert({
            text:message,
            type:type
        });
    }

    _loading(status){
        this.setState({
            loaded:status
        });
    }

	_handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    _kosongBox(params){
        if(params){
            this.setState({
                xTanggal:'',
            xNama:'',
            xEmail:'',
            xTelepon:'',
            xNegara:'',
            xNamaPengantin:'',
            xEmailPengantin:'',
            xTelpPengantin:'',
            xNegaraPengantin:'',
            xTempatNikah:'',
            xJmlTamu:'',
            xBudget:'',
            xFind:'',
            xMessage:'',

            xFullScale:'Y',
            xLegal:'Y',
            xFlower:'Y',
            xMakeUp:'Y',
            xCatering:'Y',
            xOpenBar:'Y',
            xWeddingCake:'Y',
            xEntertainment:'Y',
            xSound:'Y',
            xLighting:'Y',
            xStaging:'Y',
            xDance:'Y',
            xFireworks:'Y',
            xPhotography:'Y',
            xVideography:'Y',
            xTransportation:'Y',
            xPrewedding:'Y',
            xPostWedding:'Y',
            xWeddingArrangements:'Y',
            xActivities:'Y',
            xAccomodation:'Y',
            });
        }
    }

    _dataTableNagara(params){
        var ar=[];
        this.setState({
            dataNegara:[]
        });

        if(params.data.length>0){
            params.data.map((item,index)=>{
                ar.push({
                     id:item.id_negara,
                     value:item.nama_negara,
                });
            });
        }

        this.setState({
            dataNegara: ar,
            //totalItem:params.jml
        });

    }

    _dataTematNikah(params){
        var ar=[];
        this.setState({
            dataTempatNikah:[]
        });

        if(params.data.length>0){
            params.data.map((item,index)=>{
                ar.push({
                     id:item.id_tempat_nikah,
                     value:item.nama_tempat_nikah,
                });
            });
        }

        this.setState({
            dataTempatNikah: ar,
            //totalItem:params.jml
        });

    }

    _dataTableBudget(params){
        var ar=[];
        this.setState({
            dataBudget:[]
        });

        if(params.data.length>0){
            params.data.map((item,index)=>{
                ar.push({
                     id:item.id_budget,
                     value:item.nama_budget,
                });
            });
        }

        this.setState({
            dataBudget: ar,
            //totalItem:params.jml
        });
    }

    _dataTableFind(params){
        var ar=[];
        this.setState({
            dataFind:[]
        });

        if(params.data.length>0){
            params.data.map((item,index)=>{
                ar.push({
                     id:item.id_find_me,
                     value:item.nama_find_me,
                });
            });
        }

        this.setState({
            dataFind: ar,
            //totalItem:params.jml
        });

    }

    componentDidMount(){
        // this.fetchData();
        if(!this.state.isReady){
            this.setState({
                isReady:true
            })
        }
    }

/*fetchData = (params) => {

  this._loading(true);
  let axiosConfig = {
  headers: {
      "Access-Control-Allow-Origin": "*",
  }
};


    axios.post('http://justmarriedbaliwedding.com/services/SendMail.php',qs.stringify(params)).then((response) => {
        console.log(response);
        if(this.response){
            this._notif('success','Message has been sent. We will contact you soon. Thank you')
            //return false;
        }else{
            this._notif('error','Message could not be sent. Mailer Error.');
        }
            
    });
    this.interval = setInterval(() => {
        this._notif('success','Message has been sent. We will contact you soon. Thank you')
        this._kosongBox(true);

        this._loading(false);
        clearInterval(this.interval);
    },1000);

}*/

	render(){
        return (
            <RequestAPI>
                <Context.Consumer>
                    {(context) =>{
                        if(!this.state.isReady){
                            context.getComboNegara(
                                '',
                                this._dataTableNagara.bind(this)
                            );

                            context.getComboTempatNikah(
                                '',
                                this._dataTematNikah.bind(this)
                            );

                            context.getComboBudget(
                                '',
                                this._dataTableBudget.bind(this)
                            );

                            context.getComboFind(
                                '',
                                this._dataTableFind.bind(this)
                            );

                        }

                        return(
                            <React.Fragment>

        	<section id="contact" className="contact">
        		<div className="container">
        			<div className="row text-center">
        				<div className="col col-sm-12">
                    		<h1 className="heading"><span>are you attending</span></h1>
                    		<p className="sub-heading">For any enquiry or question please feel free to contact us directly on :</p>
                		</div>
        			</div>
        			<div className="row">
        				<div className="col col-sm-12 col-lg-6" style={{marginBottom:"20px"}}>
        					<form>
        						<div className="form-row">
        							<h1 className="heading"><span>Front Office</span></h1>
        						</div>
        						<div className="form-row">
        							<div className="col-sm-3">
        								<p><i className="fa fa-envelope-o"></i> Email :</p>
        							</div>
        							<div className="col-sm-9">
        								<p>info@justmarriedbaliwedding.com</p>
        							</div>
        						</div>
        						<div className="form-row">
        							<div className="col-sm-3">
        								<p><i className="fa fa-envelope-o"></i> Office :</p>
        							</div>
        							<div className="col-sm-9">
        								<p>office@justmarriedbaliwedding.com</p>
        							</div>
        						</div>
        						<div className="form-row">
        							<div className="col-sm-3">
        								<p><i className="fa fa-phone"></i> Phone :</p>
        							</div>
        							<div className="col-sm-9">
        								<p>087861645650</p>
        							</div>
        						</div>
        						<div className="form-row">
        							<div className="col-sm-3">
        								<p><i className="fa fa-comments-o"></i> Whatsapp :</p>
        							</div>
        							<div className="col-sm-9">
        								<p>087861645650</p>
        							</div>
        						</div>
        						<div className="form-row">
        							<div className="col-sm-3">
        								<p><i className="fa fa-home"></i> Address :</p>
        							</div>
        							<div className="col-sm-9">
        								<p>
        									Kalimutu Street No. 30 B. Denpasar, Bali
        								</p>
        							</div>
        						</div>
        					</form>
        				</div>
        				<div className="col col-sm-12 col-lg-6">
        					<div className="contact-form justify-content-center">
                            <BlockUi tag="div" blocking={this.state.loaded}>
        						<form>
                                    <div className="form-row" style={{marginBottom:"10px"}}>
                                        <h1 className="heading"><span>Are you interested?</span></h1>
                                    </div>
        							<div className="form-row">
                                		<div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Name of Bride :</label>
                                    		<input type="text" className="form-control" name="xNama" value={this.state.xNama} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                		</div>
                                    </div>
                                    <div className="form-row">
                                		<div className="form-group col-md-12 wow slideInRight">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Email :</label>
                                    		<input type="email" className="form-control" name="xEmail" value={this.state.xEmail} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                		</div>
                            		</div>
                            		<div className="form-row">
                                		<div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Phone :</label>
                                    		<input type="text" className="form-control" name="xTelepon" value={this.state.xTelepon} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                		</div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Nationality :</label>
                                		  <select className="form-control" name="xNegara" value={this.state.xNegara} onChange={this._handleInputChange.bind(this)}>
                                                <option value='' selected>Choose Nationality</option>
                                                {
                                                    this.state.dataNegara.map((item,index)=>{
                                                        return(
                                                            <option key={"row_negara_"+index} value={item.id}>{item.value}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                            		</div>
                                    <hr />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Name of Groom :</label>
                                            <input type="text" className="form-control" name="xNamaPengantin" value={this.state.xNamaPengantin} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInRight">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Email :</label>
                                            <input type="email" className="form-control" name="xEmailPengantin" value={this.state.xEmailPengantin} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Phone :</label>
                                            <input type="text" className="form-control" name="xTelpPengantin" value={this.state.xTelpPengantin} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Nationality :</label>
                                          <select className="form-control" name="xNegaraPengantin" value={this.state.xNegaraPengantin} onChange={this._handleInputChange.bind(this)}>
                                                <option value='' selected>Choose Nationality</option>
                                                {
                                                    this.state.dataNegara.map((item,index)=>{
                                                        return(
                                                            <option key={"row_negara_pengantin_"+index} value={item.id}>{item.value}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Date & year of wedding :</label>
                                            <input type="text" className="form-control" name="xTanggal" value={this.state.xTanggal} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Wedding Venue :</label>
                                             <select className="form-control" name="xTempatNikah" value={this.state.xTempatNikah} onChange={this._handleInputChange.bind(this)}>
                                                <option value='' selected>Choose Place</option>
                                                {
                                                    this.state.dataTempatNikah.map((item,index)=>{
                                                        return(
                                                            <option key={"row_tempat_nikah_"+index} value={item.id}>{item.value}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Number of Guest :</label>
                                            <input type="text" className="form-control" name="xJmlTamu" value={this.state.xJmlTamu} onChange={this._handleInputChange.bind(this)} placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Budget :</label>
                                             <select className="form-control" name="xBudget" value={this.state.xBudget} onChange={this._handleInputChange.bind(this)}>
                                                <option value='' selected>Choose Budget</option>
                                                {
                                                    this.state.dataBudget.map((item,index)=>{
                                                        return(
                                                            <option key={"row_budget_"+index} value={item.id}>{item.value}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>How did you find us :</label>
                                             <select className="form-control" name="xFind" value={this.state.xFind} onChange={this._handleInputChange.bind(this)}>
                                                <option value='' selected>Choose</option>
                                                {
                                                    this.state.dataFind.map((item,index)=>{
                                                        return(
                                                            <option key={"row_find_"+index} value={item.id}>{item.value}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Full Scale Coordination :</label>
                                             <select className="form-control" name="xFullScale" value={this.state.xFullScale} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Legal wedding :</label>
                                             <select className="form-control" name="xLegal" value={this.state.xLegal} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Flower and decoration :</label>
                                             <select className="form-control" name="xFlower" value={this.state.xFlower} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Make up and hair do :</label>
                                             <select className="form-control" name="xMakeUp" value={this.state.xMakeUp} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Catering Service :</label>
                                             <select className="form-control" name="xCatering" value={this.state.xCatering} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Open Bar :</label>
                                             <select className="form-control" name="xOpenBar" value={this.state.xOpenBar} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Wedding Cake :</label>
                                             <select className="form-control" name="xWeddingCake" value={this.state.xWeddingCake} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Entertainment :</label>
                                             <select className="form-control" name="xEntertainment" value={this.state.xEntertainment} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Sound System :</label>
                                             <select className="form-control" name="xSound" value={this.state.xSound} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Lighting :</label>
                                             <select className="form-control" name="xLighting" value={this.state.xLighting} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Staging :</label>
                                             <select className="form-control" name="xStaging" value={this.state.xStaging} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Dance Floor :</label>
                                             <select className="form-control" name="xDance" value={this.state.xDance} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Fireworks :</label>
                                             <select className="form-control" name="xFireworks" value={this.state.xFireworks} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Photography Service :</label>
                                             <select className="form-control" name="xPhotography" value={this.state.xPhotography} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Videography Service :</label>
                                             <select className="form-control" name="xVideography" value={this.state.xVideography} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Transportation :</label>
                                             <select className="form-control" name="xTransportation" value={this.state.xTransportation} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Pre Wedding Arrangements :</label>
                                             <select className="form-control" name="xPrewedding" value={this.state.xPrewedding} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Post Wedding Arrangements :</label>
                                             <select className="form-control" name="xPostWedding" value={this.state.xPostWedding} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Activities :</label>
                                             <select className="form-control" name="xActivities" value={this.state.xActivities} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Guest Accomodation :</label>
                                             <select className="form-control" name="xAccomodation" value={this.state.xAccomodation} onChange={this._handleInputChange.bind(this)}>
                                                <option value='Y'>Yes</option>
                                                <option value='T'>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="form-row">
                                        <div className="form-group col-md-12 wow slideInLeft">
                                            <label style={{color:"#fff", fontSize:"1rem",fontFamily: "sans-serif"}}>Message :</label>
                                            <textarea className="form-control" rows="5" name="xMessage" value={this.state.xMessage} onChange={this._handleInputChange.bind(this)}></textarea>
                                        </div>
                                    </div>



                            		<div className="row justify-content-center">
                                		<div className="col-12 wow slideInUp">
                                    		<button type="button" onClick={()=>{
                                                //console.log('click');
                                                /*if(this.state.xFirstName==''||this.state.xLastname==''||this.state.xSubject==''||this.state.xEmail==''||this.state.xMessage==''){
                                                    this._notif('notice','The form is not complete.');
                                                    return false;
                                                }

                                                var params={
                                                    nama:this.state.xFirstName+" "+this.state.xLastname,
                                                    sender:this.state.xEmail,
                                                    subject:this.state.xSubject,
                                                    message:this.state.xMessage
                                                }
                                                this.fetchData(params);*/

                                                if(this.state.xNama=='' || this.state.xEmail==''){
                                                     this._notif('notice','The form is not complete.');
                                                    return false;
                                                }

                                                var params={
                                                    nama:this.state.xNama,
                                                    email:this.state.xEmail,
                                                    telp:this.state.xTelepon,
                                                    negara:this.state.xNegara,
                                                    nama_pengantin:this.state.xNamaPengantin,
                                                    email_pengantin:this.state.xEmailPengantin,
                                                    telp_pengantin:this.state.xTelpPengantin,
                                                    negara_pengantin:this.state.xNegaraPengantin,
                                                    tgl_acara:this.state.xTanggal,
                                                    tempat_nikah:this.state.xTempatNikah,
                                                    jml_tamu:this.state.xJmlTamu,
                                                    budget:this.state.xBudget,
                                                    find:this.state.xFind,
                                                    full_scale:this.state.xFullScale,
                                                    legal:this.state.xLegal,
                                                    flower:this.state.xFlower,
                                                    make_up:this.state.xMakeUp,
                                                    catering:this.state.xCatering,
                                                    wedding_cake:this.state.xWeddingCake,
                                                    open_bar:this.state.xOpenBar,
                                                    entertainment:this.state.xEntertainment,
                                                    sound:this.state.xSound,
                                                    lighting:this.state.xLighting,
                                                    staging:this.state.xStaging,
                                                    dance:this.state.xDance,
                                                    firework:this.state.xFireworks,
                                                    photo:this.state.xPhotography,
                                                    video:this.state.xVideography,
                                                    transportation:this.state.xTransportation,
                                                    prewedding:this.state.xPrewedding,
                                                    post_wedding:this.state.xPostWedding,
                                                    activities:this.state.xActivities,
                                                    accomodation:this.state.xAccomodation,
                                                    message:this.state.xMessage
                                                }

                                                context.kirimKontak(
                                                    params,
                                                    this._notif.bind(this),
                                                    this._loading.bind(this),
                                                    this._kosongBox.bind(this)
                                                );

                                            }} className="btn btn-light btn-block">Send Message</button>
                                		</div>
                            		</div>
        						</form>
                                </BlockUi>
        					</div>
        				</div>
        			</div>
        		</div>
        	</section>

            </React.Fragment>
            )
        }}
        </Context.Consumer>
        </RequestAPI>

        )
    }
}

export default Contact;