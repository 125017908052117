import React, { Component } from "react";
import { render } from 'react-dom';
import { Context, RequestAPI} from '../configs/RequestAPI';
import {
	showTanggal,
    limitWords
} from '../configs/Utils';
var Loader = require('react-loader');

class Review extends Component{
	constructor(props) {
        super(props);
        this.state = {
            isReady:false,
            dataTable:[],
            loaded:false,
        }
    }

    _dataTable(params){
        var ar=[];
        this.setState({
            dataTable:[]
        });

        if(params.data.length>0){
          	params.data.map((item,index)=>{
                var img="http://156.67.219.84:8001/storage/testimonial/no-image-just-married-bali-wedding.png";
            	
                ar.push({
            		tanggal:item.tgl_create,
            		nama:item.nama,
            		isi_testimonial:item.isi_testimonial,
                    foto:img
            	})
            })
         }

        this.setState({
            dataTable: ar,
            totalItem:params.jml
        });

    }

    _loading(status){
        this.setState({
            loaded:status
        });
    }

    componentDidMount() {
        if(!this.state.isReady){
             this.setState({
                isReady:true
             });
        }
    }

	render(){
        return (
        	<RequestAPI>
                <Context.Consumer>
                    {(context) =>{
                    	if(!this.state.isReady){
                    		context.getReview(
                    			'',
                    			this._dataTable.bind(this)
                    		);
                    	}

                    	return(
                            <React.Fragment>
                            	<section id="testimonial" className="friends-wishes">
                            		<div className="container">
                            			<div className="row text-center">
                							<div className="col col-sm-12">
                								<h1 className="heading"><span>Testimonials</span></h1>
                							</div>
                						</div>
                            			<div className="row">
                							<div className="col-md-12">
                								<div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="5000">
                									<ol className="carousel-indicators">
                									{
                										
                										this.state.dataTable.map((item,index) =>{
                											var aktif="";
                											if(index==0){
                												aktif='active';
                											}
                											return(
                            									<li data-target="#carouselExampleControls" data-slide-to={index} className={aktif}></li>
                            								)
                            							})
                        							}
                        							</ol>
                        							<div className="carousel-inner" role="listbox">
                        								{
                        									this.state.dataTable.map((item,index) =>{
                											var aktif="carousel-item";
                											if(index==0){
                												aktif='carousel-item active';
                											}
                											return(
                        								<div className={aktif}>
                                							<div className="carousel-caption">
                                								 <div className="row">
                                                                    <div class="col-12 col-sm-12">
                                                                        <img src={item.foto} alt="" class="rounded-circle wow bounceIn" />
                                                                    </div>
                                								 	<div className="col-12 feedback-message">
                                								 		<p>{limitWords(item.isi_testimonial,100)}</p>
                                            							<h5><b>{item.nama}</b></h5>
                                            							<p>{"( "+showTanggal(item.tanggal)+" )"}</p>
                                								 	</div>
                                								 </div>
                                							</div>
                                						</div>
                                							)
                										})
                                						}

                        							</div>
                								</div>
                							</div>
                						</div>

                            		</div>
                            	</section>
                            </React.Fragment>
                        )
                    }}
                </Context.Consumer>
            </RequestAPI>
        )
    }
}

export default Review;