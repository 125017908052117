import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

//slider image
import sliderContent from "./../layout/images/slider_content.png";
import slider1 from "./../layout/images/slider/slider-bali-wedding-1.jpg";
import slider2 from "./../layout/images/slider/slider-bali-wedding-2.jpg";
import slider3 from "./../layout/images/slider/slider-bali-wedding-3.jpg";
import slider4 from "./../layout/images/slider/slider-bali-wedding-4.jpg";
import slider5 from "./../layout/images/slider/slider-bali-wedding-5.jpg";

class Home extends Component{
	render(){
        return (
        	<div id="home" className="main-slider">
        		<div id="carousel-just-married-bali-wedding" className="carousel slide" data-ride="carousel">
        			<ol className="carousel-indicators">
                		<li data-target="#carousel-just-married-bali-wedding" data-slide-to="0" className="active">
                		</li>
                		<li data-target="#carousel-just-married-bali-wedding" data-slide-to="1"></li>
                		<li data-target="#carousel-just-married-bali-wedding" data-slide-to="2"></li>
                		<li data-target="#carousel-just-married-bali-wedding" data-slide-to="3"></li>
                		<li data-target="#carousel-just-married-bali-wedding" data-slide-to="4"></li>
            		</ol>
            		<div className="carousel-inner">
            			<div className="carousel-item active">
                    		<img className="slider-image" src={slider2} alt="First slide" />
                    		{/*<div className="carousel-caption">
                        		<div className="underlay"></div>
                        		<div className="content">
                            		<p>
                                		Just Married Bali Wedding  is working with a number of professional local and western wedding companies on the island and will be able to provide you with many options and recommendations on such components as wedding venues, wedding sites, food and beverage for wedding reception party, wedding ceremony, wedding decorations, wedding entertainment, wedding bouquet and buttonhole, wedding make up, wedding photography, wedding videograpy.
                            		</p>
                            		<a href="#contact" className="smoothscroll btn btn-light">Contact us</a>
                        		</div>
                    		</div>*/}
                		</div>
                		<div className="carousel-item">
                    		<img className="slider-image" src={slider3} alt="Second slide" />
                    		{/*<div className="carousel-caption ">
                        		<div className="underlay"></div>
                        		<div className="content">
                            		<p>
                                		Just Married Bali Wedding planner would be more than happy to provide you with your personalized wedding package and pricing based on your individual wedding ceremony requirements and simply require you to click on the inquiry form and select your choice of wedding ceremony plus items that you would like included in your wedding ceremony, provide information where necessary and that you feel relevant and forward the completed inquiry form back to us by simply clicking on submit. Or just give us a call, text message or what’s app.
                            		</p>
                            		<a href="#contact" className="smoothscroll btn btn-light">Contact us</a>
                        		</div>
                    		</div>*/}
                		</div>
                		<div className="carousel-item">
                    		<img className="slider-image" src={slider1} alt="Second slide" />
                    		{/*<div className="carousel-caption ">
                        		<div className="underlay"></div>
                        		<div className="content">
                            		<p>
                                		Is your dream wedding just for a simple, big, romantic on the beach or an elaborate event in the most remote areas in Bali though – Just Married Bali Wedding ready to assist you and being your WEDDING PLANNER prior until the wedding day.
                            		</p>
                            		<a href="#contact" className="smoothscroll btn btn-light">Contact us</a>
                        		</div>
                    		</div>*/}
                		</div>
                		<div className="carousel-item">
                    		<img className="slider-image" src={slider4} alt="Second slide" />
                    		{/*<div className="carousel-caption ">
                        		<div className="underlay"></div>
                        		<div className="content">
                            		<p>
                                		Lots of choices for many wedding couple who is planning a wedding in Bali, be it from the options, supporting the event, even with the WEDDING PLANNER and WEDDING ORGANIZER.
With experience and patience we were always faithful to listen and help couples to realize their dream wedding by adjusting capacity or their budget.
                            		</p>
                            		<a href="#contact" className="smoothscroll btn btn-light">Contact us</a>
                        		</div>
                    		</div>*/}
                		</div>
                		<div className="carousel-item">
                    		<img className="slider-image" src={slider5} alt="Second slide" />
                    		{/*<div className="carousel-caption ">
                        		<div className="underlay"></div>
                        		<div className="content">
                            		<p>
                                		The landscape and the scenic beauty of the beaches of Bali, adds to the charm of the decor. Having a Destination Wedding in Bali could mean a fairytale setting for your wedding day. If it’s been your lifelong dream to tie the knot at a beach during sunset, having a Destination Wedding in BALI is the way to go.
                            		</p>
                            		<a href="#contact" className="smoothscroll btn btn-light">Contact us</a>
                        		</div>
                    		</div>*/}
                		</div>

            		</div>
        		</div>
        	</div>
        )
    }
}

export default Home;