import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import logo from "./../layout/images/logo/logo-just-married-bali-wedding.gif";

class NavBar extends Component{
	render(){
        return (
        	<nav className="navbar fixed-top navbar-expand-lg navbar-toggleable-md bg-white">
        		<div className="container">
        			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbar" aria-controls="mainNavbar" aria-expanded="false" aria-label="Toggle navigation">
						<span className="fa fa-navicon fa-lg"></span>
					</button>
					<a className="smoothscroll navbar-brand" href="#home"><img src={logo} alt="logo just married bali wedding" style={{width:"180px", height:"60px"}} /></a>
					<div className="collapse navbar-collapse flex-row-reverse" id="mainNavbar">
						<ul className="navbar-nav">
							<li className="nav-item">
                        		<a className="smoothscroll nav-link" href="#home">Home</a>
                    		</li>
                    		<li className="nav-item">
                        		<a className="smoothscroll nav-link" href="#about">About us</a>
                    		</li>
                    		<li className="nav-item">
                        		<a className="smoothscroll nav-link" href="#gallery">Photo gallery</a>
                    		</li>
                            <li className="nav-item">
                                <a className="smoothscroll nav-link" href="#testimonial">Testimonial</a>
                            </li>
                    		<li className="nav-item">
                        		<a className="smoothscroll nav-link" href="#contact">Contact</a>
                    		</li>
						</ul>
					</div>
        		</div>
        	</nav>
        )
    }
}

export default NavBar; 