import React, { Component } from 'react';

const Context = React.createContext();

class RequestAPI extends Component {
	constructor(props) {
		super(props);

		this.api = {
			proto: '',
            //host: '',
            host: '156.67.219.84',
			port: 8001,
            //path: '/services'
            path: 'api'
        };
    }

    getUrl() {
        return 'http://' + this.api.host + ':' + this.api.port + '/' + this.api.path;
    }

    getGalleryFoto(params,dataTable,notif,loading,paging,offset,limit){
        var xhttp = new XMLHttpRequest();

        function updateProgress(){
            console.log('Data Transfering');
            loading(true);
        }

        function transferComplete(){
            if(this.status==401){
                notif('error','Session Expired, Silahkan logout dan login kembali.');
                loading(false);
            }

            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        dataTable(respon);
                        paging(respon);
                        loading(false);
                    }else{
                        notif('error',respon.message);
                        loading(false);
                    }
                }else{
                    notif('error','Tidak ada respon dari server.');
                    loading(false);
                }
            }else{
                notif('error','Tidak ada respon dari server.');
                loading(false);
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
            notif('error','Gagal terhubungan dengan server. Pastikan device anda terhubung dengan internet.')
            loading(false);
        }

        function transferCanceled(){
            console.log('Transfer cancel');
            notif('error','Batal terhubung dengan server.')
            loading(false);
        }

        var urlToPass="?offset="+offset+"&limit="+limit+"&user=";
        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.open("GET", this.getUrl()+'/gallery/foto'+urlToPass);
       // xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send();
    }

    kirimKontak(params,notif,loading,kosongBox){
        var xhttp = new XMLHttpRequest();
        var self=this;
        function updateProgress(){
            console.log('Data Transfering');
            loading(true);
        }

        function transferComplete(){
            
            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        notif('success',respon.message);
                        kosongBox(true)
                        loading(false);
                    }else{
                        loading(false);
                        notif('error',respon.message);
                    }
                }else{
                    notif('error','Tidak ada respon dari web service.');
                    loading(false);
                }
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
            notif('error','Gagal terhubungan dengan web service. Pastikan device anda terhubung dengan internet.')
            loading(false);
        }

        function transferCanceled(){
            console.log('Transfer cancel');
            notif('error','Batal terhubung dengan web service.')
            loading(false);
        }
        
        var urlToPass='nama='+params.nama
        +'&email='+params.email
        +'&telepon='+params.telp
        +'&negara='+params.negara
        +'&nama_pengantin='+params.nama_pengantin
        +'&email_pengantin='+params.email_pengantin
        +'&telepon_pengantin='+params.telp_pengantin
        +'&id_negara_pengantin='+params.negara_pengantin
        +'&id_tempat_nikah='+params.tempat_nikah
        +'&jumlah_tamu='+params.jml_tamu
        +'&id_budget='+params.budget
        +'&find_me='+params.find
        +'&full_scale='+params.full_scale
        +'&legal_wedding='+params.legal
        +'&flower_and_decoration='+params.flower
        +'&make_up='+params.make_up
        +'&catering='+params.catering
        +'&open_bar='+params.open_bar
        +'&wedding_cake='+params.wedding_cake
        +'&entertainment='+params.entertainment
        +'&sound='+params.sound
        +'&lighting='+params.lighting
        +'&staging='+params.staging
        +'&dance='+params.dance
        +'&firework='+params.firework
        +'&photo='+params.photo
        +'&video='+params.video
        +'&transportation='+params.transportation
        +'&pre_wedding='+params.prewedding
        +'&wedding_arrangements='+params.post_wedding
        +'&activities='+params.activities
        +'&accomodation='+params.accomodation
        +'&message='+params.message
        +'&tgl_acara='+params.tgl_acara;
        xhttp.open("POST", this.getUrl()+'/kontak');
        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        //xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send(urlToPass);
    }

    getComboNegara(params,dataTable){
        var xhttp = new XMLHttpRequest();

        function updateProgress(){
            console.log('Data Transfering');
        }

        function transferComplete(){

            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        dataTable(respon);
                    }
                }
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
        }

        function transferCanceled(){
            console.log('Transfer cancel');
        }

        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.open("GET", this.getUrl()+'/negara/combo');
        //xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send();
    }

    getComboBudget(params,dataTable){
        var xhttp = new XMLHttpRequest();

        function updateProgress(){
            console.log('Data Transfering');
        }

        function transferComplete(){

            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        dataTable(respon);
                    }
                }
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
        }

        function transferCanceled(){
            console.log('Transfer cancel');
        }

        var urlToPass='?offset=0&limit=100';
        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.open("GET", this.getUrl()+'/budget'+urlToPass);
        //xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send();
    }

    getComboFind(params,dataTable){
        var xhttp = new XMLHttpRequest();

        function updateProgress(){
            console.log('Data Transfering');
        }

        function transferComplete(){

            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        dataTable(respon);
                    }
                }
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
        }

        function transferCanceled(){
            console.log('Transfer cancel');
        }

        var urlToPass='?offset=0&limit=100&user=';
        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.open("GET", this.getUrl()+'/find-me'+urlToPass);
        //xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send();
    }

    getComboTempatNikah(params,dataTable){
        var xhttp = new XMLHttpRequest();

        function updateProgress(){
            console.log('Data Transfering');
        }

        function transferComplete(){

            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        dataTable(respon);
                    }
                }
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
        }

        function transferCanceled(){
            console.log('Transfer cancel');
        }

        var urlToPass='?offset=0&limit=100&user=';
        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.open("GET", this.getUrl()+'/tempat-nikah'+urlToPass);
        //xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send();
    }

    getReview(params,dataTable){
        var xhttp = new XMLHttpRequest();

        function updateProgress(){
            console.log('Data Transfering');
        }

        function transferComplete(){

            if(this.status==200){
                if(this.response){
                    var respon=JSON.parse(this.response);
                    if(respon.success){
                        dataTable(respon);
                    }
                }
            }
        }

        function transferFailed(){
            console.log('Transfer failed');
        }

        function transferCanceled(){
            console.log('Transfer cancel');
        }

        var urlToPass='?offset=0&limit=100&user=';
        xhttp.addEventListener("progress",updateProgress);
        xhttp.addEventListener("load",transferComplete);
        xhttp.addEventListener("error",transferFailed);
        xhttp.addEventListener("abort",transferCanceled);
        xhttp.open("GET", this.getUrl()+'/testimonial'+urlToPass);
        //xhttp.setRequestHeader('Authorization','Bearer '+sessionStorage.getItem('loginAuth'));
        xhttp.send();
    }

     render(){
    	return(
    		<Context.Provider value={{
    			getGalleryFoto:this.getGalleryFoto.bind(this),
                kirimKontak:this.kirimKontak.bind(this),
                getComboNegara:this.getComboNegara.bind(this),
                getComboTempatNikah:this.getComboTempatNikah.bind(this),
                getComboBudget:this.getComboBudget.bind(this),
                getComboFind:this.getComboFind.bind(this),
                getReview:this.getReview.bind(this)
    		}}>{this.props.children}</Context.Provider>
    	)
    }

}

export {
	Context,
	RequestAPI
};