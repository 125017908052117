import React, { Component } from "react";

class Peta extends Component{
	render(){
        return (
        	
        <iframe id="peta" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.2257289010367!2d115.19585481478397!3d-8.670070793770323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd240b7b5b26f8d%3A0x232f60eee19e7275!2sJl.+Gn.+Kalimutu+No.30b%2C+Pemecutan+Klod%2C+Denpasar+Bar.%2C+Kota+Denpasar%2C+Bali+80112!5e0!3m2!1sid!2sid!4v1555538949120!5m2!1sid!2sid" height="540" frameBorder="0" style={{border:"0",width:"100%"}} allowFullScreen></iframe>
        )
    }
}

export default Peta;