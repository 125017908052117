import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WebFont from 'webfontloader';
//import './index.css';
import './layout/css/font-awesome.min.css';
import './layout/css/normalize.min.css';
//import './layout/css/bootstrap.min.css';
import './layout/css/animate.min.css';
import './layout/css/style.css';


WebFont.load({
  google: {
    families: ['Rufina:300,400,700','sans-serif']
  }
});

WebFont.load({
	google:{
  		families: ['Poppins:100,200,300,400,600,700,800,900','sans-serif']
  	}
});
 
ReactDOM.render(
  <App/>, 
  document.getElementById("root")
);

