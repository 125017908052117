const moment = require('moment');
const showTanggal=(param)=>{
	var respon="";
	if(param){
		respon=moment(param).format('DD/MM/YYYY');
	}
	return respon;
}

const showTanggalJam=(tgl)=>{
    var result="";
    if(tgl){
        result=moment(tgl).format('DD/MM/YYYY hh:mm:ss');
    }
   	return result;       
}

const limitWords = (textToLimit, wordLimit)=>
{
	var finalText = "";
	//var text2 = textToLimit.replace(/\s+/g, '');
	//console.log(text2);
	var text3 = textToLimit.split(' ');
	var numberOfWords = text3.length;
	var i=0;

	if(numberOfWords > wordLimit){
		for(i=0; i< wordLimit; i++)
			finalText = finalText+' '+ text3[i]+' ';
			return finalText+'… [Read more]';
	}
	else return textToLimit;
}

export{
	showTanggal,
	showTanggalJam,
	limitWords
}