import React, { Component } from "react";

import moto from "./../layout/images/logo/moto-just-married-bali-wedding.png";

class Planning extends Component{
	render(){
        return (
        	<section id="planning" className="about-us">
        		<div className="container">
        			<div className="row text-center">
                		<div className="col col-sm-12">
                    		<h1 className="heading">Start planning your bespoke Bali wedding today</h1>
                    		<p className="sub-heading">
                    			To find out more about planning your special wedding day in Bali and a little more about us, our team and what we can do for you, please fill out the below contact form and one of our dedicated planners will get back to you with a prompt response.
                    		</p>
                    		<p className="sub-heading">
                    			Just Married Bali Wedding planner is a professional, experienced and flexible wedding company in Bali. Just Married Bali Wedding planner is a partnership of talented people. Our partners have arranged dozens of happy wedding experiences here on the island of Bali.
                    		</p>
                    		<p className="sub-heading">
                    			Just Married  Bali Wedding planner is made up of dynamic people of various expertise. We blend together our diverse strengths–extensive knowledge of local wedding venues and operations, Balinese and Indonesian culture, and an international overview.
Focused on your satisfaction and happiness in the whole Bali experience, we still keep an eye on the needs of our local and international client.
                    		</p>

                    		{/*<p className="sub-heading">
                    			Just Married Bali Wedding  is working with a number of professional local and western wedding companies on the island and will be able to provide you with many options and recommendations on such components as wedding venues, wedding sites, food and beverage for wedding reception party, wedding ceremony, wedding decorations, wedding entertainment, wedding bouquet and buttonhole, wedding make up, wedding photography, wedding videograpy.
                    		</p>
                    		<p className="sub-heading">
                    			Just Married Bali Wedding planner would be more than happy to provide you with your personalized wedding package and pricing based on your individual wedding ceremony requirements and simply require you to click on the inquiry form and select your choice of wedding ceremony plus items that you would like included in your wedding ceremony, provide information where necessary and that you feel relevant and forward the completed inquiry form back to us by simply clicking on submit. Or just give us a call, text message or what’s app.
                    		</p>
                    		<p className="sub-heading">
                    			Is your dream wedding just for a simple, big, romantic on the beach or an elaborate event in the most remote areas in Bali though – Just Married Bali Wedding ready to assist you and being your WEDDING PLANNER prior until the wedding day.
                    		</p>
                    		<p className="sub-heading">
                    			Lots of choices for many wedding couple who is planning a wedding in Bali, be it from the options, supporting the event, even with the WEDDING PLANNER and WEDDING ORGANIZER.
With experience and patience we were always faithful to listen and help couples to realize their dream wedding by adjusting capacity or their budget.
                    		</p>
                    		<p className="sub-heading">
                    			As a Bali Wedding Planner and Event Organizer, we position ourselves as a representative of the client, to be able to manage all the supporters of the event to be used at their wedding day from the smallest detail to detail is very important. Because we understand that every wedding couple is unique and will never be forgotten for life.
                    		</p>
                    		<p className="sub-heading">
                    			The landscape and the scenic beauty of the beaches of Bali, adds to the charm of the decor. Having a Destination Wedding in Bali could mean a fairytale setting for your wedding day. If it’s been your lifelong dream to tie the knot at a beach during sunset, having a Destination Wedding in BALI is the way to go.
                    		</p>*/}
                    		<p className="sub-heading">
                    			Destination Wedding in Bali will be enjoyable and lively for your guests, which will be a memory for them forever, but can be daunting for you to plan your wedding alone in a foreign country. It is often very difficult to meet all the vendors personally and also due to language barriers it’s difficult to communicate with them. So this is where we as a wedding planner can help you.
                    		</p>
                            <p>
                                <img className="slider-content" src={moto} />
                            </p>
                    	</div>
                    </div>
                </div>
            </section>
        )

    }
}

export default Planning;