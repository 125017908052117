import React, { Component } from "react";

class Footer extends Component{
	render(){
        return (
        	<footer className="footer">
        		<div className="container">
        			<div className="row justify-content-center footer-menu">
        				<div className="col col-sm-12">
                    		<ul className="list-inline text-center">
                        		<li className="list-inline-item wow fadeIn" data-wow-delay="0.7s">
                            		<a className="smoothscroll" href="#home">home</a>
                        		</li>
                        		<li className="list-inline-item wow fadeIn" data-wow-delay="0.6s">
                            		<a className="smoothscroll" href="#about">about us</a>
                        		</li>
                        		<li className="list-inline-item wow fadeIn" data-wow-delay="0.4s">
                            		<a className="smoothscroll" href="#gallery">photo gallery</a>
                        		</li>
                        		<li className="list-inline-item wow fadeIn">
                            		<a className="smoothscroll" href="#contact">contact</a>
                        		</li>
                    		</ul>
                		</div>
        			</div>
        			<div className="row justify-content-center">
        				<div className="col col-sm-12">
                    		<div className="social">
                    			<ul className="list-inline text-center">
                            		<li className="list-inline-item wow fadeIn"><a href="https://www.facebook.com/justmarried.baliwedding" target="_blank"><i className="fa fa-facebook fa-lg icon" aria-hidden="true"></i></a></li>
                            		<li className="list-inline-item wow fadeIn" data-wow-delay="0.1s"><i className="fa fa-twitter fa-lg icon" aria-hidden="true"></i></li>
                            		<li className="list-inline-item wow fadeIn" data-wow-delay="0.2s"><a href="https://www.instagram.com/just_marriedbaliwedding" target="_blank"><i className="fa fa-instagram fa-lg icon" aria-hidden="true"></i></a></li>
                        		</ul>
                    		</div>
                    	</div>
        			</div>
        			<div className="copyright-section">
                		<p className="text-center">&copy; 2019 all rights reserved</p>
            		</div>
        		</div>
        	</footer>
        )
    }
}

export default Footer;