import React, { Component } from "react";
import { Route, NavLink, HashRouter } from "react-router-dom";
import NavBar from "./layout/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Planning from "./pages/Planning";
import Gallery from "./pages/Gallery";
import Review from "./pages/Review";
import Contact from "./pages/Contact";
import Peta from "./pages/Peta";
import Footer from "./pages/Footer";
//import logo from './logo.svg';
//import './App.css';

class App extends Component {
  render() {
    return (
      <div>
          <NavBar />
          <Home />
          <About />
          <Gallery />
          <Review />
          <Planning />
          <Contact />
          <Peta />
          <Footer />
      </div>
    );
  }
}

export default App;
