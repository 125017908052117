import React, { Component } from "react";


class About extends Component{
	render(){
        return (
    		<section id="about" className="about-us">
        		<div className="container">
        			<div className="row text-center">
                		<div className="col col-sm-12">
                    		<h1 className="heading"><span>Welcome to</span></h1>
                    		<h1 className="heading">Just Married Bali Wedding & Events Bali</h1>
                    		<p className="sub-heading">
                    			If you’re looking for a unique way to say “I do” while surrounded by your closest friends and family members, a destination wedding in Bali is ideal. Just Married Bali  Wedding  are an experienced team of qualified wedding planning professionals based in Bali, Indonesia. Our approach is a personal, bespoke and flexible one. Our dedicated planning team is here to ensure that both the lead up to your wedding and the day itself are everything you imagined and more.
                    		</p>
                		</div>
            		</div>
            		
        		</div>
        	</section>
        )
    }
}

export default About;